const actionTypes = {
  FetchCurrentOrder: 'FETCH_CURRENT_ORDER',
  DriverFetched: 'DRIVER_FETCHED',
  LoadOrderHistory: 'LOAD_ORDER_HISTORY',
  FetchDriverRunOrder: 'FETCH_DRIVER_RUN_ORDER',
  FetchAllShifts: 'FETCH_ALL_SHIFTS',
  FetchHalfHalf: 'FETCHED_HALF_HALF',
  FetchStoreInfo: 'FETCH_STORE_INFO',
};

export default actionTypes;
