import { combineReducers } from 'redux';
import reviewOrderReducer from './containers/ReviewOrder/reducer';
import homeReducer from './containers/Home/reducer';
import orderReducer from './containers/Order/reducer';
import layoutReducer from './containers/Layout/reducer';
import orderTrackerReducer from './containers/OrderTracker/reducer';
import reviewReducer from './containers/Reviews/reducer';
import reservationReducer from './containers/ConfirmReservation/reducer';
import pendingOrderReducer from './containers/ConfirmOrder/reducer';

const rootReducer = combineReducers({
  homeReducer,
  reviewReducer,
  reviewOrderReducer,
  orderReducer,
  layoutReducer,
  orderTrackerReducer,
  reservationReducer,
  pendingOrderReducer,
});

export default rootReducer;
